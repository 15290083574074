.contact {
  padding: 60px 0 50px 0;
  background: linear-gradient(190deg, rgba(0, 0, 1, 1) 19%, rgb(67, 158, 177) 100%);
}

.img-container {
  width: 80%;
  margin: 0 auto;
}

.contact img {
  width: 90%;
}
.contact h2 {
  display: flex;
  justify-content: center;
  font-size: 45px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}
.contact form input,
.contact form textarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  background: rgba(68, 68, 68, 0.739);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  color: #fff;
  padding: 14px 23px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.px-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 10px 35px;
  font-size: 18px;
  margin-top: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .img-container {
    width: 60% !important;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .img-container {
    width: 50% !important;
    margin: 0 auto;
  }
}
