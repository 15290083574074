.col {
  text-align: left;
  padding-left: 1em;
}

.banner {
  padding: 220px 0 100px 0;
  background-image: url("../../assets/img/banner-bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(44, 44, 47, 0.5) -5.91%, rgb(101, 101, 101) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  font-size: 10px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
}

.txt-rotate {
  font-size: 33px;
}

.banner p {
  color: #a3ebeb;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 30px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

@media screen and (min-width: 464px) {
  .banner p {
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 85%;
  }
}

@media screen and (min-width: 1024px) {
  .banner p {
    width: 70%;
  }
}
