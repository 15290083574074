.skill {
  padding: 0 0 50px 0;
  position: relative;
  background: linear-gradient(160deg, rgba(0, 0, 1, 1) 19%, rgb(67, 158, 177) 100%);
}
.skill-bx {
  background: rgb(34, 34, 34);
  border-radius: 20px;
  text-align: center;
  padding: 40px 50px;
  margin-top: -60px;
}

.skill h2 {
  font-size: 40px;
  font-weight: 700;
}
.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.react-multiple-carousel__arrow {
  min-width: 33px !important;
  min-height: 33px !important;
}

.skill-slider .item img {
  width: 20%;
  margin: 0 auto 12px auto;
}

@media screen and (min-width: 464px) {
  .skill-slider {
    width: 97%;
    margin: 0 auto;
    position: relative;
  }

  .skill-slider .item img {
    width: 25%;
    margin: 0 auto 12px auto;
  }
  .react-multiple-carousel__arrow {
    margin-left: -0.8em;
    margin-right: -0.8em;
  }
}
